import DEFAULT_SCHEMA from "./default-schema.json";
import ART_SCHEMA from "./art-schema.json";
import DIPLOMA_SCHEMA from "./diploma-schema.json";
import WATER_SCHEMA from "./water-schema.json";

export default [
    DEFAULT_SCHEMA,
    ART_SCHEMA,
    DIPLOMA_SCHEMA,
    WATER_SCHEMA
];