import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import SCHEMAS from "./token-schemas";
import EXAMPLE_TOKENS from "./example-tokens";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    private fallbackSchema: any;

    public headerLinks: any[] = [];
    public tokenId: string = "ART";
    public displayToken: boolean = false;
    public tokenType: string;
    public tokenSchema: any;
    public token: any = {};
    public sectionToggles: any = {};
    public overlayToggles: any = {};
    public dialogStates: any = {};
    public listStates: any = {};

    public imagePreviewVisible: boolean = false;
    public previewImageUrl: SafeResourceUrl;

    constructor(
        private _sanitizer: DomSanitizer
    ) {
        this.fallbackSchema = SCHEMAS.find(schema => schema.type === "default");
    }

    ngOnInit() {
        this.headerLinks = [
            {
                key: "howitworks",
                label: "How It Works",
                url: "",
                children: [
                    {
                        label: "Bespoke Tokenization",
                        url: "https://www.tokenx.is/bespoke/"
                    },
                    {
                        label: "API Solutions",
                        url: "https://www.tokenx.is/developers/"
                    }
                ]
            },
            {
                key: "solutions",
                label: "Solutions",
                url: "",
                children: [
                    {
                        label: "Authentication",
                        url: "https://www.tokenx.is/authentication/"
                    },
                    {
                        label: "Transparency",
                        url: "https://www.tokenx.is/transparency/"
                    },
                    {
                        label: "Engagement",
                        url: "https://www.tokenx.is/engagement/"
                    }
                ]
            },
            {
                key: "demo",
                label: "Request Demo",
                url: "https://www.tokenx.is/#home-contact",
                style: "button"
            }
        ];

        this.overlayToggles["links"] = false;

        this.headerLinks.forEach(link => {
            if (link.children && link.children.length > 0) {
                this.overlayToggles[link.key] = false;
            }
        });

        this.fetchToken();
    }

    resetToken() {
        this.displayToken = false;
        this.tokenId = "";
    }

    fetchToken() {
        // TODO: add to a service call with this.tokenId to fetch tokenType and token info
        // this.tokenType = "art";
        // this.tokenType = "diploma";
        this.tokenType = this.tokenId.toLowerCase();
        
        // Load schema based on tokenType
        this.tokenSchema = SCHEMAS.find(schema => {
            return schema.type === this.tokenType;
        }) || Object.assign({}, this.fallbackSchema);

        this.sectionToggles = {};
        this.dialogStates = {};

        this.tokenSchema.tabs.forEach((tab: any) => {
            switch(tab.tabType) {
                case "content": {
                    tab.content.forEach((section: any) => {
                        if (section.expandable) {
                            this.sectionToggles[section.sectionKey] = false;
                        }
    
                        if (section.sectionType === "carousel") {
                            this.dialogStates[section.sectionKey] = false;
                        }
                    });
                    break;
                }
    
                case "list": {
                    this.dialogStates[tab.key] = false;
                    this.listStates[tab.key] = 0;
                    break;
                }
            }
        });

        // Load token info
        this.token = EXAMPLE_TOKENS.find(token => token.tokenType === this.tokenType);

        this.displayToken = true;
    }

    toggleSection(key: string) {
        this.sectionToggles[key] = !this.sectionToggles[key];
    }

    toggleOverlay(key: string) {
        this.overlayToggles[key] = !this.overlayToggles[key];
    }

    toggleDialog(key: string) {
        this.dialogStates[key] = !this.dialogStates[key];
    }

    openRowInDialog(key: string, index: number) {
        this.listStates[key] = index;
        this.toggleDialog(key);
    }

    formResourceURL(url: string) {
        return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    showPreviewDialog(url: string) {
        if (url) {
            this.previewImageUrl = this.formResourceURL(url);
            this.imagePreviewVisible = true;
        }
    }

    goToUrl(url: string) {
        window.open(url, "_blank");
    }
}