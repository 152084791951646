<div class="header">
    <div class="header-logo"
        (click)="resetToken()"
    >
        <img src="assets/images/tokenx-logo.png">
    </div>

    <div class="links-con-compact" (click)="toggleOverlay('links')">
        <span class="material-symbols-outlined">menu</span>
    </div>

    <div class="links-con" [class.shown]="overlayToggles['links']">
        <ul id="header-links">
            <li *ngFor="let link of headerLinks">
                <p-button *ngIf="link.style === 'button'"
                    [label]="link.label"
                    (click)="goToUrl(link.url)"
                ></p-button>

                <a *ngIf="link.style !== 'button' && !link.children"
                    [href]="link.url"
                    target="_blank"
                    (click)="toggleOverlay(link.key)"
                >
                    {{ link.label }}
                </a>

                <a *ngIf="link.style !== 'button' && link.children"
                    (click)="toggleOverlay(link.key)"
                >
                    {{ link.label }}
                    <span class="material-symbols-outlined">keyboard_arrow_down</span>
                </a>

                <p-overlay *ngIf="link.children"
                    [(visible)]="overlayToggles[link.key]"
                >
                    <ul class="overlay-links">
                        <li *ngFor="let child of link.children">
                            <a [href]="child.url" target="_blank">
                                {{ child.label }}
                            </a>
                        </li>
                    </ul>
                </p-overlay>
            </li>
        </ul>
    </div>
</div>

<div class="page-body">
    <!-- Pre-fetch screen -->
    <div *ngIf="!displayToken" class="token-con">
        <div class="header-con">
            <div class="page-title">
                View Tokens
            </div>

            <div class="page-description">
                Enter your Token ID to view the full token information.
            </div>
        </div>

        <div class="body-con">
            <div class="body-title">
                View Token
            </div>

            <div class="input-row">
                <div class="input-con">
                    <label for="tokenId">Enter Token ID</label>
                    <input type="text" pInputText
                        [(ngModel)]="tokenId"
                        placeholder="Token ID"
                        (keydown.enter)="fetchToken()"
                    />
                </div>

                <div class="button-con">
                    <p-button label="View Token"
                        [style]="{width: '100%', height: '3em'}"
                        (onClick)="fetchToken()"
                    ></p-button>
                </div>
            </div>

            <p-dialog [(visible)]="dialogState">
                <div>
                    <span class="material-symbols-filled">cancel</span> Token ID not found
                    The Token ID you entered cannot be found.
                </div>

                <div>
                    <span class="material-symbols-outlined">visibility_lock</span> Private Token Found
                    The Token ID you entered can not be publicly viewed.
                </div>
            </p-dialog>
        </div>
    </div>

    <!-- Post-fetch screen -->
    <div *ngIf="displayToken && token" class="display-con">
        <div class="preview-con">
            <div *ngIf="token.metadata[tokenSchema.imageKey]" class="preview-img">
                <img [src]="token.metadata[tokenSchema.imageKey]"/>
            </div>

            <div *ngIf="!token.metadata[tokenSchema.imageKey]" class="preview-placeholder">
                <img [src]="'assets/images/placeholders/' + tokenType + '-token.png'"/>
            </div>

            <div *ngIf="token.metadata as details" class="preview-details">
                <div class="token-title">
                    <div class="token-name">
                        {{ details[tokenSchema.titleKey] }}
                    </div>
                    
                    <div *ngIf="tokenSchema.taglineKey" class="tagline">
                        {{ details[tokenSchema.taglineKey] }}
                    </div>
                </div>

                <div *ngIf="tokenSchema.ownerKey" class="token-owner">
                    <span *ngIf="tokenType === 'art'">
                        Owned by
                    </span>

                    <span *ngIf="tokenType === 'diploma'">
                        Received by
                    </span>

                    {{ details[tokenSchema.ownerKey] }}
                </div>
                
                <!-- These rows are more generic from the schema -->
                <div class="metadata-rows">
                    <div *ngFor="let row of tokenSchema.metadata" class="token-row">
                        <div *ngIf="row.label" class="label">
                            {{ row.label }}
                        </div>
    
                        <ng-container [ngSwitch]="row.type">
                            <div *ngSwitchCase="'file'" class="value">
                                <img [src]="details[row.key]"/>
                            </div>

                            <div *ngSwitchCase="'object'" class="value">
                                <div *ngFor="let item of row.keys">
                                    {{ item.label }}: {{ details[row.key][item.key] }}
                                </div>
                            </div>

                            <div *ngSwitchCase="'list'" class="value">
                                <ul>
                                    <li *ngFor="let item of details[row.key]">
                                        {{ item }}
                                    </li>
                                </ul>
                            </div>

                            <div *ngSwitchDefault class="value">
                                {{ details[row.key] }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="tabs-con">
            <p-tabView>
                <p-tabPanel *ngFor="let tab of tokenSchema.tabs"
                    [header]="tab.title + ( (tab.tabType === 'list') ? (' (' + (token[tab.key]?.length || 0) + ')') : '')"
                >
                    <div *ngIf="tab.tabType === 'content'" class="panel-body-con">
                        <div *ngFor="let section of tab.content"
                            class="card-con"
                            [style.flex-basis]="'calc(' + section.sectionWidth + '%' + (section.sectionWidth < 100 ? ' - 10px' : '') + ')'"
                        >
                            <p-card [header]="section.sectionTitle" [ngSwitch]="section.sectionType">
                                <div *ngSwitchCase="'carousel'" class="carousel-con">
                                    <p-carousel [value]="token[tab.key][section.sectionKey]"
                                        [numVisible]="4"
                                        [numScroll]="1"
                                    >
                                        <ng-template let-file pTemplate="item">
                                            <div class="item-con">
                                                <div *ngIf="!file.url" class="img-con">
                                                    <img [src]="'assets/images/thumbnails/' + (file.type || 'doc') + '.png'">
                                                </div>

                                                <div *ngIf="file.url" class="img-con">
                                                    <iframe *ngIf="file.type !== 'video'"
                                                        [src]="formResourceURL(file.url)"
                                                        width="100px"
                                                        height="100px"
                                                    ></iframe>

                                                    <!-- Iframe for Video link -->
                                                    <iframe *ngIf="file.type === 'video'"
                                                        width="100px"
                                                        height="100px"
                                                        [src]="formResourceURL(file.url)"
                                                        title="YouTube video player"
                                                        frameborder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        referrerpolicy="strict-origin-when-cross-origin"
                                                        allowfullscreen
                                                    ></iframe>
                                                </div>
    
                                                <div class="item-description">
                                                    <div class="item-name"
                                                        [class.pointer]="file.url"
                                                        (click)="showPreviewDialog(file.url)"
                                                    >
                                                        {{ file.name }}
                                                    </div>
    
                                                    <div class="item-meta">
                                                        {{ file.type === "video" ? file.length : file.type }}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-carousel>

                                    <p-dialog [(visible)]="dialogStates[section.sectionKey]"
                                        [header]="section.sectionTitle"
                                        [modal]="true"
                                        [draggable]="false"
                                        [resizable]="false"
                                        [dismissableMask]="true"
                                        [style]="{
                                            'width': '50vw',
                                            'padding-bottom': '30px'
                                        }"
                                    >
                                        <div class="carousel-dialog-con">
                                            <div *ngFor="let file of token[tab.key][section.sectionKey]" class="dialog-item">
                                                <div class="img-con">
                                                    <img *ngIf="!file.url"
                                                        [src]="'assets/images/thumbnails/' + (file.type || 'doc') + '.png'"
                                                    >

                                                    <ng-container *ngIf="file.url">
                                                        <iframe *ngIf="file.type !== 'video'"
                                                            [src]="formResourceURL(file.url)"
                                                            width="100px"
                                                            height="100px"
                                                        ></iframe>

                                                        <!-- Iframe for Video link -->
                                                        <iframe *ngIf="file.type === 'video'"
                                                            width="100px"
                                                            height="100px"
                                                            [src]="formResourceURL(file.url)"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                            referrerpolicy="strict-origin-when-cross-origin"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </ng-container>
                                                </div>
    
                                                <div class="item-description">
                                                    <div class="item-name"
                                                        [class.pointer]="file.url"
                                                        (click)="showPreviewDialog(file.url)"
                                                    >
                                                        {{ file.name }}
                                                    </div>
    
                                                    <div class="item-meta">
                                                        {{ file.type === "video" ? file.length : file.type }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngFor="let _ of [].constructor((3 - (token[tab.key][section.sectionKey].length % 3)) % 3)"
                                                class="blank-item"
                                            ></div>
                                        </div>
                                    </p-dialog>

                                    <p-dialog [(visible)]="imagePreviewVisible"
                                        [modal]="true"
                                        [draggable]="false"
                                        [resizable]="false"
                                        [dismissableMask]="true"
                                        [style]="{
                                            'height': '50vw',
                                            'width': '50vw',
                                            'padding-bottom': '30px'
                                        }"
                                    >
                                        <div class="preview-dlg-body">
                                            <iframe
                                                width="100%"
                                                height="100%"
                                                [src]="previewImageUrl"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerpolicy="strict-origin-when-cross-origin"
                                                allowfullscreen
                                            ></iframe>
                                        </div>
                                    </p-dialog>
                                </div>

                                <div *ngSwitchCase="'fields'" class="card-body-con card-fields"
                                    [class.truncated]="!sectionToggles[section.sectionKey]"
                                >
                                    <div *ngFor="let field of section.properties" class="field-row">
                                        <label class="field-label">
                                            {{ field.label }}
                                        </label>

                                        {{ token[tab.key][section.sectionKey][field.key] }}
                                    </div>
                                </div>

                                <div *ngSwitchCase="'list'" class="card-body-con card-list"
                                    [class.truncated]="!sectionToggles[section.sectionKey]"
                                >
                                    <div *ngFor="let item of token[tab.key][section.sectionKey]" class="list-row">
                                        <div class="item-title">
                                            {{ item.label }}:
                                        </div>

                                        <ul class="item-sublist">
                                            <li *ngFor="let note of item.notes">
                                                {{ note }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'rows'" class="card-body-con card-rows"
                                    [class.truncated]="token[tab.key][section.sectionKey]?.length > 4 && !sectionToggles[section.sectionKey]"
                                >
                                    <div *ngFor="let row of token[tab.key][section.sectionKey]" class="row-item">
                                        <div *ngFor="let col of section.properties" class="row-col">
                                            <div *ngFor="let field of col" class="row-field"
                                                [style.font-weight]="field.weight"
                                            >
                                                {{ row[field.key] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'image'" class="card-body-con card-image">
                                    <img [src]="token.metadata[section.sectionKey]"/>
                                </div>

                                <div *ngSwitchCase="'file-list'" class="card-body-con file-rows"
                                    [class.truncated]="token[tab.key][section.sectionKey]?.length > 4 && !sectionToggles[section.sectionKey]"
                                >
                                    <div *ngFor="let row of token[tab.key][section.sectionKey]" class="row-item">
                                        <div class="row-image">
                                            <img [src]="'assets/images/thumbnails/' + (row.type || 'doc') + '.png'"/>
                                        </div>

                                        <div class="row-name">
                                            {{ row.name }}
                                        </div>

                                        <div class="row-type">
                                            {{ row.type }}
                                        </div>
                                    </div>
                                </div>

                                <ng-template pTemplate="footer">
                                    <p-button *ngIf="section.sectionType === 'carousel'"
                                        label="Show All"
                                        styleClass="p-button-link"
                                        (onClick)="toggleDialog(section.sectionKey)"
                                    ></p-button>

                                    <p-button *ngIf="sectionToggles[section.sectionKey] !== undefined"
                                        styleClass="p-button-link"
                                        [label]="sectionToggles[section.sectionKey] ? 'Show less' : 'Show more'"
                                        (onClick)="toggleSection(section.sectionKey)"
                                    ></p-button>
                                </ng-template>
                            </p-card>
                        </div>
                    </div>

                    <div *ngIf="tab.tabType === 'list'" class="panel-body-con">
                        <div class="panel-table-con">
                            <table>
                                <tr class="header-row">
                                    <th class="spacer"></th>

                                    <th *ngFor="let field of tab.tableFields" class="{{field.styleClass}}">
                                        {{ field.label }}
                                    </th>

                                    <th class="link-col"></th>
                                </tr>

                                <tr *ngFor="let row of token[tab.key]; let i=index;"
                                    class="item-row"
                                    (click)="openRowInDialog(tab.key, i)"
                                >
                                    <td class="spacer"></td>

                                    <td *ngFor="let field of tab.tableFields" class="{{field.styleClass}}">
                                        <img *ngIf="field.styleClass === 'image'" [src]="row[field.key]" />
                                        
                                        <span *ngIf="field.styleClass !== 'image'">
                                            {{ row[field.key] }}
                                        </span>
                                    </td>

                                    <td class="link-col">
                                        <span class="material-symbols-outlined">chevron_right</span>
                                    </td>
                                </tr>
                            </table>

                            <p-dialog [(visible)]="dialogStates[tab.key]"
                                [style]="{ width: '50%' }"
                                [draggable]="false"
                                [resizable]="false"
                                [dismissableMask]="true"
                            >
                                <div>
                                    <p-accordion [activeIndex]="listStates[tab.key]">
                                        <p-accordionTab *ngFor="let row of token[tab.key]">
                                            <ng-template pTemplate="header">
                                                <div class="tab-header">
                                                    <div class="header-img">
                                                        <img [src]="row[tab.dialogFields[row.type].imageKey]" />
                                                    </div>
    
                                                    <div class="header-text">
                                                        <div class="header-name">
                                                            {{ row[tab.dialogFields[row.type].titleKey] }}
                                                        </div>
    
                                                        <div class="header-id">
                                                            {{ row[tab.dialogFields[row.type].subtitleKey] }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
    
                                            <ng-template pTemplate="content">
                                                <div class="tab-content">
                                                    <div *ngFor="let field of (tab.dialogFields[row.type]?.rows || [])" class="field-row">
                                                        <div class="label">
                                                            {{ field.label }}
                                                        </div>
        
                                                        <div class="value">
                                                            {{ row.metadata[field.key] }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                    </p-accordion>
                                </div>
                            </p-dialog>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>

    <div class="footer-con">
        <div class="branding-con">
            <img src="assets/images/tokenx-logo-dark.png">

            <p>
                TokenX is a LifeSite product.
            </p>

            <p>
                © 2016 - 2025 LifeSite All Rights Reserved.
            </p>

            <div class="cl"></div>
        </div>
        
        <div class="nav-con">
            <div class="nav-col">
                <p class="col-title">
                    Solutions
                </p>

                <ul>
                    <li>
                        <a href="https://www.tokenx.is/how-it-works/" target="_blank">
                            How it Works
                        </a>
                    </li>

                    <li>
                        <a href="https://www.tokenx.is/bespoke/" target="_blank">
                            Bespoke Tokenization
                        </a>
                    </li>
                    
                    <li>
                        <a href="https://www.tokenx.is/developers/" target="_blank">
                            API Solutions
                        </a>
                    </li>

                    <li class="blank"></li>
                    
                    <li>
                        <a href="https://www.tokenx.is/authentication/" target="_blank">
                            Authentication
                        </a>
                    </li>
                    
                    <li>
                        <a href="https://www.tokenx.is/transparency/" target="_blank">
                            Transparency
                        </a>
                    </li>
                    
                    <li>
                        <a href="https://www.tokenx.is/engagement/" target="_blank">
                            Engagement
                        </a>
                    </li>
                </ul>
            </div>
            
            <div class="nav-col">
                <p class="col-title">
                    Resources
                </p>

                <ul>
                    <li>
                        <a href="https://www.lifesite.co/" target="_blank">
                            About LifeSite
                        </a>
                    </li>

                    <li>
                        <a href="https://www.lifesite.co/contact-us/" target="_blank">
                            Contact Us
                        </a>
                    </li>
                    
                    <li>
                        <a href="https://www.tokenx.is/blog/" target="_blank">
                            Blog
                        </a>
                    </li>
                    
                    <li>
                        <a href="https://www.lifesite.co/terms-of-service/" target="_blank">
                            Terms of Service
                        </a>
                    </li>
                    
                    <li>
                        <a href="https://www.lifesite.co/privacy-policy/" target="_blank">
                            Privacy Policy
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>